@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito/nunito-bold-webfont.woff2') format('woff2'),
         url('/fonts/Nunito/nunito-bold-webfont.woff') format('woff'),
		 url('/fonts/Nunito/Nunito-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito/nunito-bolditalic-webfont.woff2') format('woff2'),
         url('/fonts/Nunito/nunito-bolditalic-webfont.woff') format('woff'),
		 url('/fonts/Nunito/Nunito-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito/nunito-extralight-webfont.woff2') format('woff2'),
         url('/fonts/Nunito/nunito-extralight-webfont.woff') format('woff'),
		 url('/fonts/Nunito/Nunito-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito/nunito-extralightitalic-webfont.woff2') format('woff2'),
         url('/fonts/Nunito/nunito-extralightitalic-webfont.woff') format('woff'),
		 url('/fonts/Nunito/Nunito-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito/nunito-regular-webfont.woff2') format('woff2'),
         url('/fonts/Nunito/nunito-regular-webfont.woff') format('woff'),
		 url('/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito/nunito-italic-webfont.woff2') format('woff2'),
         url('/fonts/Nunito/nunito-italic-webfont.woff') format('woff'),
		 url('/fonts/Nunito/Nunito-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito/nunito-light-webfont.woff2') format('woff2'),
         url('/fonts/Nunito/nunito-light-webfont.woff') format('woff'),
		 url('/fonts/Nunito/Nunito-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito';
    src: url('/fonts/Nunito/nunito-lightitalic-webfont.woff2') format('woff2'),
         url('/fonts/Nunito/nunito-lightitalic-webfont.woff') format('woff'),
		 url('/fonts/Nunito/Nunito-LightItalic.ttf') format('truetype');
    font-style: 300;
	font-style: italic;
}
