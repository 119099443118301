@import 'common/colors';
@import 'common/fonts';

////////////////////////////////////

.text-primary {
	color:	$purple;
}

.btn {
	text-transform:	none;
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
	padding-top:	0;
}

body.auth-layout {
	.checkbox-styled:not(ie8) input ~ span::before, .radio-styled:not(ie8) input ~ span::before {
		top:	4px;
	}
}

.btn-primary {
	background-color:	$purple;
	border-color:		$purple;
	
	&:hover, &:focus, &:active {
		background-color:	$purple_dark;
		border-color:		$purple_dark;
		color:				$white;
	}
}

.btn-secondary {
	background-color:	$purple;
	border-color:		$purple;
	color:				$white;
	
	&:hover, &:focus, &:active {
		background-color:	$white;
		border-color:		$white;
		color:				$purple;
	}	
}

.btn-accent:focus {
	color:	$white;
}

.form-group .form-control ~ label::after, .form-group .form-control ~ .form-control-line::after {
	background-color:	$purple;
}

.fa {
	font-size:		1.2em;
    margin-bottom:	0.2em;
    margin-top:		0.2em;
}

.divider {
	width:		10px;
	display:	inline-block;
}

#content {
	
	.card-container {
		padding:	0 5%;
	}
}

.menubar-pin .gui-folder > a::after, 
.gui-controls > li ul > li::before,
.gui-controls > li ul > li::before,
.gui-controls > li ul > li > a::before {
	display:	none;
}

.card-head {
    line-height:	20px;
    min-height:		20px;
    padding:		10px;
}

.card-body.no-bottom {
	padding-bottom:	0;
}

.nav-tabs a:focus {
	outline:	0 none;
}

.icon-header {
	padding:	0 10px;
	
	i {
		margin-bottom:	0;
	}
}